import { zodResolver } from "@hookform/resolvers/zod";
import type { HttpError } from "@refinedev/core";
import { useList } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { useAtom } from "jotai";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { MapCard } from "@/components/map/map";
import { hectaresFromGeojson } from "@/components/map/utils";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useIdentity } from "@/utility/user";

import { currentFeatureAtom } from "./state";

const formSchema = z.object({
  name: z.string().optional(),
  json: z
    .object({})
    .passthrough()
    .refine((val) => val.type, {
      message: "Please draw a polygon",
    }),
});
type Data = z.infer<typeof formSchema> & { id: string };
type FieldValues = Data & { user_id: string };

export const PlotCreate = () => {
  const [type, setType] = useState<"polygon" | "point">("polygon");
  const { data: user } = useIdentity();
  const navigate = useNavigate();
  const { data: plots } = useList<Data>({
    resource: "plots",
    pagination: {
      mode: "off",
    },
    queryOptions: {
      enabled: false,
    },
  });
  const nextPlotNumber = (plots?.total || 0) + 1;

  const form = useForm<Data, HttpError, FieldValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      json: {},
    },
    refineCoreProps: {
      resource: "plots",
      action: "create",
      redirect: "show",
    },
  });

  const [currentFeature, setCurrentFeature] = useAtom(currentFeatureAtom);

  useEffect(() => {
    // @ts-expect-error - this is a bit of a botch, should probs fix but not a priority
    form.setValue("json", currentFeature || {});
  }, [currentFeature, form]);

  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    setCurrentFeature(null);
    setSearchParams({ type });
  }, [setSearchParams, type, setCurrentFeature]);

  const onSubmit = (data: Data) => {
    setCurrentFeature(null);

    form.refineCore
      .onFinish({
        ...data,
        name: data.name || "Plot " + nextPlotNumber,
        user_id: user!.id,
      })
      .catch(console.error);
  };

  return (
    <MapCard>
      <div className="flex w-[380px] items-center justify-between p-4">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight">
          Create Plot
        </h1>
        <div>
          <Button
            onClick={() => {
              navigate("/plots");
            }}
            variant="secondary"
          >
            <ArrowLeft size={16} />
            List
          </Button>
        </div>
      </div>
      <div className="px-4 pb-4">
        {form.refineCore.formLoading ? (
          <>Loading...</>
        ) : (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid gap-4">
                <div>
                  <div className="mb-2 text-sm font-medium text-stone-600">
                    Draw a polygon or point on the map
                  </div>
                  <RadioGroup
                    onValueChange={(value) =>
                      setType(value as "polygon" | "point")
                    }
                    defaultValue={type}
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="polygon" id="polygon" />
                      <Label htmlFor="polygon">Polygon</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="point" id="point" />
                      <Label htmlFor="point">Point</Label>
                    </div>
                  </RadioGroup>
                </div>
                {currentFeature && (
                  <>
                    <div className="rounded-md border bg-muted p-4">
                      <div className="font-bold text-stone-700">Area</div>
                      {hectaresFromGeojson(currentFeature).toFixed(2)}
                      ha
                    </div>
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Plot name (optional)</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="json"
                      render={() => (
                        <FormItem>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <Button type="submit" className="w-full">
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </form>
          </Form>
        )}
      </div>
    </MapCard>
  );
};
