import { useNavigation, useShow } from "@refinedev/core";
import { AnimatePresence, motion } from "framer-motion";
import { useAtomValue } from "jotai";
import { ArrowLeft, LucideDownload } from "lucide-react";
import type { ReactNode } from "react";

import { MapCard } from "@/components/map/map";
import { rubberBaselineLayerConfigAtom } from "@/components/map/state";
import { hectaresFromGeojson } from "@/components/map/utils";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import type { Tables } from "@/db-types";
import { useRubberBaselineQuery } from "@/queries/rubber-baseline";
import { toKebabCase } from "@/utility/case";

const enGBTimeFormat = new Intl.DateTimeFormat("en-GB", {
  dateStyle: "short",
  timeStyle: "short",
});

const saveAsFile = (data: GeoJSON.Feature, fileName: string) => {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], {
    type: "application/json",
  });
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.download = `${fileName}.geojson`;

  link.click();
  URL.revokeObjectURL(href);
};

type AreaCardProps = {
  value: ReactNode;
  title: string;
};

const AreaCard = ({ value, title }: AreaCardProps) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center rounded-lg border p-4 text-center">
      <div className="flex flex-grow items-center text-stone-700">{value}</div>
      <div className="pt-2 text-sm text-stone-500">{title}</div>
    </div>
  );
};

type SlideAndFadeProps = {
  children: ReactNode;
  isVisible: boolean;
  duration?: number;
};

const SlideAndFade = ({
  children,
  isVisible,
  duration = 0.15,
}: SlideAndFadeProps) => {
  // Variants for the animation states
  const variants = {
    hidden: { opacity: 0, y: -10 }, // Initial state (fade out and slide up)
    visible: { opacity: 1, y: 0 }, // Final state (fade in and slide down)
    exit: { opacity: 0, y: -10 }, // Exit state (fade out and slide up)
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
          transition={{ duration }} // Animation duration (default: 0.5)
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const PlotShow = () => {
  const { list } = useNavigation();
  const {
    queryResult: { data, isLoading },
  } = useShow<Tables<"plots">>({});
  const plot = data?.data;

  const { data: rubberBaselineData } = useRubberBaselineQuery(plot);
  const rubberConfig = useAtomValue(rubberBaselineLayerConfigAtom);

  if (isLoading || !plot) {
    return (
      <MapCard>
        <div className="min-w-[380px] p-4">
          <Skeleton className="mb-3 h-8 w-20" />
          <Skeleton className="mb-1 h-2 w-40" />
          <Skeleton className="mb-6 h-2 w-40" />
          <div className="flex space-x-4">
            <Skeleton className="mb-3 h-28 w-1/2" />
            <Skeleton className="mb-3 h-28 w-1/2" />
          </div>
          <Skeleton className="ml-auto h-10 w-40" />
        </div>
      </MapCard>
    );
  }

  const plotArea = hectaresFromGeojson(plot.json);
  const intersectionPercentage =
    (plot.eugfc_intersection_percentage ?? 0) * 100;
  const intersectionArea = (plotArea * intersectionPercentage) / 100;

  return (
    <div className="space-y-3">
      <MapCard>
        <div className="flex w-[380px] flex-col p-4">
          <div className="flex items-center justify-between">
            <h1 className="overflow-hidden truncate whitespace-nowrap text-2xl font-extrabold tracking-tight">
              {plot.name}
            </h1>
            <Button
              onClick={() => {
                list("plots");
              }}
              variant="secondary"
            >
              <ArrowLeft size={16} className="mr-2" />
              List
            </Button>
          </div>

          <div className="space-y-6">
            <div className="text-left text-xs text-stone-500">
              <div>
                Created {enGBTimeFormat.format(new Date(plot.created_at))}
              </div>
              <div>
                Updated {enGBTimeFormat.format(new Date(plot.updated_at))}
              </div>
            </div>

            <div className="flex space-x-4">
              <AreaCard
                value={
                  <div className="text-3xl font-bold">
                    {hectaresFromGeojson(plot.json).toFixed(0)}
                    <span className="text-sm">ha</span>
                  </div>
                }
                title="Total Area"
              />

              <AreaCard
                value={
                  !intersectionPercentage ? (
                    <div className="text-3xl font-bold text-green-600">
                      0<span className="text-sm">ha</span>
                    </div>
                  ) : (
                    <div>
                      <div className="text-3xl font-bold text-yellow-600">
                        {intersectionArea.toFixed(0)}
                        <span className="text-sm">ha</span>
                      </div>
                      <div className="text-xs text-yellow-600">
                        {intersectionPercentage.toFixed(0)}% of total area
                      </div>
                    </div>
                  )
                }
                title="EU Forest Map"
              />
            </div>

            <div className="flex justify-end">
              <Button
                onClick={() => {
                  saveAsFile(plot.json, toKebabCase(plot.name));
                }}
                className="w-fit"
              >
                <LucideDownload className="mr-2 h-4 w-4" />
                Download GeoJSON
              </Button>
            </div>
          </div>
        </div>
      </MapCard>
      <SlideAndFade isVisible={rubberConfig.isEnabled}>
        <Card className="ml-3 w-fit">
          <CardContent className="p-4">
            <AreaCard
              value={
                rubberBaselineData ? (
                  <div className="text-xl font-bold">
                    {rubberBaselineData.coverageStats.percentage.toFixed(1)}%
                  </div>
                ) : (
                  <Skeleton className="h-7 w-16" />
                )
              }
              title="Rubber baseline"
            />
          </CardContent>
        </Card>
      </SlideAndFade>
    </div>
  );
};
