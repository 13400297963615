"use client";

import * as Collapsible from "@radix-ui/react-collapsible";
import type { Table } from "@tanstack/react-table";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ListFilterIcon,
  XIcon,
} from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useState } from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

import { DataTableFacetedFilter } from "./data-table-faceted-filter";

type DataTableToolbarProps<TData> = {
  table: Table<TData>;
};

const deforestationStatuses = [
  {
    label: "High",
    value: "high",
    icon: ArrowUpIcon,
  },
  {
    label: "Low",
    value: "low",
    icon: ArrowDownIcon,
  },
];

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const isLegalRiskEnabled = useFeatureFlagEnabled("demo-only");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <Collapsible.Root
      className="CollapsibleRoot"
      open={isFiltersOpen}
      onOpenChange={setIsFiltersOpen}
    >
      <div className="flex flex-col">
        <div className="flex flex-1 items-center space-x-2">
          <Input
            placeholder="Filter plots..."
            value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("name")?.setFilterValue(event.target.value)
            }
            className="h-8 w-[200px] text-xs"
          />
          <Collapsible.Trigger asChild>
            <Button
              variant="outline"
              className={cn(
                "h-8 px-2 text-xs",
                isFiltersOpen && "border-stone-500",
              )}
            >
              <ListFilterIcon className="mr-2 h-4 w-4" />
              Filters
              {table.getState().columnFilters.length > 0 && (
                <Badge className="ml-2 text-xs" variant="secondary">
                  {table.getState().columnFilters.length}
                </Badge>
              )}
            </Button>
          </Collapsible.Trigger>

          {isFiltered && (
            <Button
              variant="ghost"
              onClick={() => table.resetColumnFilters()}
              className="h-8 px-2 text-xs"
            >
              Reset
              <XIcon className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
        <Collapsible.Content className="overflow-hidden transition-all duration-300 ease-out data-[state=closed]:animate-slide-up data-[state=open]:animate-slide-down">
          <div className="flex gap-2 pt-2">
            {table.getColumn("eugfc_intersection_geojson") && (
              <DataTableFacetedFilter
                column={table.getColumn("eugfc_intersection_geojson")}
                title="Deforestation Risk"
                options={deforestationStatuses}
              />
            )}
            {isLegalRiskEnabled && (
              <DataTableFacetedFilter
                column={table.getColumn("legal_risk")}
                title="Legal risk"
                options={deforestationStatuses}
              />
            )}
          </div>
        </Collapsible.Content>
      </div>
    </Collapsible.Root>
  );
}
