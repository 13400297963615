import type { IResourceComponentsProps } from "@refinedev/core";
import { useList, useNavigation } from "@refinedev/core";
import type {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MapCard } from "@/components/map/map";
import { Button } from "@/components/ui/button";
import type { Tables } from "@/db-types";

import { columns, DataTable } from "./components/data-table/data-table";
import { ImportButton } from "./components/import-dialog";

const tableWidth = 500;

const EmptyState = () => {
  const { create } = useNavigation();
  return (
    <div className="p-4 pt-0">
      <div className="flex flex-col items-center justify-center rounded-lg bg-stone-100 p-8">
        <p className="mb-4 text-lg font-medium">Add your first plots</p>
        <div className="flex space-x-4">
          <ImportButton />
          <Button onClick={() => create("plots")}>Create</Button>
        </div>
      </div>
    </div>
  );
};

export const PlotList: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const isLegalRiskEnabled = useFeatureFlagEnabled("demo-only");
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({
      legal_risk: Boolean(isLegalRiskEnabled),
    });
  useEffect(() => {
    setColumnVisibility({ legal_risk: Boolean(isLegalRiskEnabled) });
  }, [isLegalRiskEnabled]);

  const [rowSelection, setRowSelection] = React.useState({});

  const { data, isLoading } = useList<Tables<"plots">>({
    resource: "plots",
    pagination: {
      mode: "off",
    },
  });

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const hasRows = table.getPreFilteredRowModel().rows.length > 0;

  const renderTable = () => {
    if (isLoading) {
      return <div />;
    }
    if (hasRows) {
      return <DataTable {...table} />;
    }
    return <EmptyState />;
  };

  return (
    <MapCard>
      <div className="max-h-[calc(100vh-40px)] overflow-y-auto">
        <div className="flex items-center justify-between p-4">
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight">
            Plots
          </h1>

          {!isLoading && hasRows && (
            <div className="flex gap-3">
              <ImportButton />
              <Button onClick={() => navigate("/plots/create")}>Create</Button>
            </div>
          )}
        </div>
        <div style={{ width: tableWidth + "px" }}>{renderTable()}</div>
      </div>
    </MapCard>
  );
};
