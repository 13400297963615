"use client";

import type { useReactTable } from "@tanstack/react-table";
import { createColumnHelper, flexRender } from "@tanstack/react-table";
import { LucideTrash, MoreHorizontal } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { Tables } from "@/db-types";

import { DataTableToolbar } from "./data-table-toolbar";
import {
  DeleteDialog,
  DeleteDialogContent,
  DeleteDialogTrigger,
} from "./delete-button";
import { DataTablePagination } from "./pagination";

const columnHelper = createColumnHelper<Tables<"plots">>();

export const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    size: 160,
    cell: ({ row }) => {
      return (
        <div className="line-clamp-1 cursor-default">{row.original.name}</div>
      );
    },
  }),
  columnHelper.accessor("eugfc_intersection_geojson", {
    header: "Deforestation",
    size: 0,
    filterFn: (row, _columnId, filterValue: string[]) => {
      const geojson = row.original.eugfc_intersection_geojson as any;
      const isHighRisk = geojson?.features?.length;
      if (filterValue.length === 1) {
        if (filterValue[0] === "high") {
          return isHighRisk;
        }
        if (filterValue[0] === "low") {
          return !isHighRisk;
        }
      }

      return true;
    },
    cell: function render({ getValue }) {
      const geojson = getValue<{ features?: Array<any> }>();
      const isHighRisk = geojson?.features?.length;
      return (
        <Badge
          variant={isHighRisk ? "error" : "success"}
          className="cursor-default whitespace-nowrap"
        >
          {isHighRisk ? "High Risk" : "Low Risk"}
        </Badge>
      );
    },
  }),
  columnHelper.display({
    id: "legal_risk",
    size: 0,
    header: "Legality",
    cell: function render() {
      return (
        <Badge variant="secondary" className="cursor-default whitespace-nowrap">
          Standard Risk
        </Badge>
      );
    },
  }),
  columnHelper.display({
    id: "actions",
    size: 0,
    cell: ({ row }) => {
      return (
        <DeleteDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 cursor-default p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              onClick={(e) => e.stopPropagation()}
            >
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DeleteDialogTrigger className="w-full">
                <DropdownMenuItem onClick={(e) => console.log(e)}>
                  <div className="flex flex-row items-center gap-2">
                    <LucideTrash size={16} /> Delete plot
                  </div>
                </DropdownMenuItem>
              </DeleteDialogTrigger>
            </DropdownMenuContent>
          </DropdownMenu>

          <DeleteDialogContent
            resource="plots"
            id={row.original.id}
            name={row.original.name}
          />
        </DeleteDialog>
      );
    },
  }),
];

export function DataTable({
  ...table
}: ReturnType<typeof useReactTable<Tables<"plots">>>) {
  const navigate = useNavigate();

  return (
    <div className="w-full p-2">
      <DataTableToolbar table={table} />
      <div className="mt-2 rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{ width: `${header.getSize()}px` }}
                      className="px-3"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => {
                    navigate(`/plots/show/${row.original.id}`);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="px-3 py-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {table.getRowModel().rows?.length > 0 && (
        <div className="mt-2 px-2">
          <DataTablePagination table={table} />
        </div>
      )}
    </div>
  );
}
